<template>
  <div v-if="step !== null" class="tour-overlay">
    <div :style="popoverStyle" class="tour-popover">
      <div :class="arrowClass"></div>
      <v-card class="elevation-0">
        <v-card-title style="padding-bottom: 0; padding-top: 20px; color:#5271FF" class="font-weight-black">
          <v-spacer/>
          {{ currentTooltip.title }}
          <v-spacer/>
        </v-card-title>
        <v-card-text class="pl-10 pr-10 pt-5 font-weight-medium" v-html="currentTooltip.description" style="font-size: 16px;"></v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer/>
          <v-btn color="primary" outlined @click="terminate" elevation="0" class="text-none">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div class="tour-backdrop"></div>
  </div>
</template>

<script>
export default {
  name: 'CustomCardTooltip',
  props: {
    tooltips: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      step: null,
      originalOffsetTop: 0,
      originalOffsetLeft: 0,
      popoverDimensions: {
        width: 0,
        height: 0
      }
    };
  },
  computed: {
    currentTooltip() {
      return this.tooltips[this.step] || {};
    },
    popoverStyle() {
      if (this.step === null || !this.currentTooltip.selector) return {};

      const element = document.querySelector(this.currentTooltip.selector);
      const styles = {};

      if (element) {
        const rect = element.getBoundingClientRect();

        styles.position = 'fixed';

        switch (this.currentTooltip.position) {
          case 'top':
            styles.top = `${rect.top - this.popoverDimensions.height - 10}px`;
            styles.left = `${rect.left + rect.width / 2 - this.popoverDimensions.width / 2}px`;
            break;
          case 'right':
            styles.top = `${rect.top}px`;
            styles.left = `${rect.right + 10}px`;
            break;
          case 'bottom':
            styles.top = `${rect.bottom + 10}px`;
            styles.left = `${rect.left + rect.width / 2 - this.popoverDimensions.width / 2}px`;
            break;
          case 'left':
            styles.top = `${rect.top}px`;
            styles.left = `${rect.left - this.popoverDimensions.width - 10}px`;
            break;
          default:
            styles.top = `${rect.bottom + 10}px`;
            styles.left = `${rect.left + rect.width / 2 - this.popoverDimensions.width / 2}px`;
            break;
        }
      }

      return styles;
    },
    arrowClass() {
      if (this.step === null || !this.currentTooltip.position) return '';
      switch (this.currentTooltip.position) {
        case 'top':
          return 'tour-arrow-top';
        case 'right':
          return 'tour-arrow-right';
        case 'bottom':
          return 'tour-arrow-bottom';
        case 'left':
          return 'tour-arrow-left';
        default:
          return 'tour-arrow-bottom';
      }
    }
  },
  methods: {
    start(index) {
      if (index >= 0 && index < this.tooltips.length) {
        this.step = index;
        this.calculatePopoverDimensions();
        this.calculateOriginalOffset();
        window.addEventListener('scroll', this.handleScroll);
      } else {
        console.error('Índice fuera de rango: ', index);
      }
    },
    calculatePopoverDimensions() {
      const popover = this.$el.querySelector('.tour-popover');
      this.popoverDimensions.width = popover.offsetWidth;
      this.popoverDimensions.height = popover.offsetHeight;
    },
    calculateOriginalOffset() {
      const element = document.querySelector(this.tooltips[this.step].selector);
      if (element) {
        const rect = element.getBoundingClientRect();
        this.originalOffsetTop = rect.top + window.scrollY;
        this.originalOffsetLeft = rect.left + window.scrollX;
      }
    },
    terminate() {
      this.step = null;
      // window.removeEventListener('scroll', this.handleScroll);
      if (this.isScrollListenerActive) {
        window.removeEventListener('scroll', this.handleScroll);
        this.isScrollListenerActive = false;
      }
    },
    handleScroll() {
      // this.$forceUpdate(); // Actualiza la posición del tooltip cuando se hace scroll
      // this.terminate()
      if (this.step !== null) {
        this.terminate();
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
}

.tour-popover {
  position: fixed; /* Cambiado a fixed */
  background: white;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  pointer-events: auto;
  max-width: 500px;
}

.tour-popover .tour-arrow-top,
.tour-popover .tour-arrow-bottom,
.tour-popover .tour-arrow-left,
.tour-popover .tour-arrow-right {
  position: absolute;
  width: 0;
  height: 0;
}

.tour-popover .tour-arrow-top {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  bottom: -10px;
  left: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-bottom {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  top: -10px;
  left: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-left {
  border-top: 10px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid white;
  right: -5px;
  top: 20px;
  transform: translateX(-50%);
}

.tour-popover .tour-arrow-right {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  left: -5px;
  top: 10px;
  transform: translateX(-50%);
}

.tour-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
</style>
