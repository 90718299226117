import { HorizontalBar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Toneladas de CO2e' // Label for x-axis
          },
          ticks: {
            beginAtZero: true
          }
        }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Instalación' // Label for y-axis
          },
          gridLines: {
            display: false // Hide horizontal grid lines
          }
        }]
      },
      tooltips: {
        enabled: true
      }
    }
  }),
  watch: {
    chartData: function (val) {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
